/*
  Mixins
*/

@mixin bp($breakpoint) {
  @media (min-width: $breakpoint) { @content; }
}

/*
  Variables
*/

$bp-bg-video: 800px;
$bp-greeting: 800px;
$bp-article: 500px;

/*
  Imports
*/

@import '../../node_modules/normalize.css/normalize';
@import 'fullscreen';
@import 'greeting';
@import 'article';
@import 'type';

/*
  Base
*/

html {
  box-sizing: border-box;
  line-height: 1.7;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

img {
  max-width: 100%;
}

a {
  color: #0094d8;
}
