.title {
  text-align: center;
  text-transform: uppercase;
}

.tagline {
  opacity: 0.5;
  text-align: center;
  font-size: 1.125rem;
  font-weight: normal;
}

.preamble {
  text-align: center;
  font-size: 1.125rem;
}
