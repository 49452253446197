.greeting {
  font-family: "Georgia", serif;
  font-style: italic;

  a {
    display: inline-block;
    padding: 0 0.125em;
    background: transparentize(#0094d8, 0.75);
    color: white;
    text-decoration: none;
    transition: background 500ms ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background: #0094d8;
    }
  }
}

.greeting-title {
  margin: 0;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-size: 12vw;
  text-transform: uppercase;

  @include bp($bp-greeting) {
    font-size: 100px;
  }
}

.greeting-desc {
  max-width: 15em;
  margin: 0 auto;
  font-size: 5vw;

  @include bp($bp-greeting) {
    font-size: 42px;
  }
}
