.fullscreen-text {
  position: relative;
  z-index: 3;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
}

.fullscreen-text--blue {
  background: transparentize(#0094d8, 0.5);
  color: white;
  transition: background 500ms ease-in-out;

  @include bp($bp-greeting) {
    background: transparentize(#0094d8, 0.75);
  }
}

.fullscreen-image {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.fullscreen-video {
  display: none;

  @include bp($bp-bg-video) {
    position: absolute;
    z-index: 2;
    display: block;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s opacity;
  }
}
